import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ScrollSideMenu, { ScrollSideMenuProps } from "./ScrollSideMenu";
import { SystemFlagEnum } from "@mds/common/constants/enums";
import { getSystemFlag } from "@mds/common/redux/selectors/authenticationSelectors";

interface ScrollSidePageWrapperProps {
  content: ReactNode;
  menuProps?: ScrollSideMenuProps;
  header: ReactNode;
  extraItems?: ReactNode;
  headerHeight?: number;
  view?: "default" | "steps" | "anchor";
}

export const coreHeaderHeight = 62; // match scss variable $header-height
const msHeaderHeight = 80;

/**
 * A wrapper component that provides a side menu and a content area. The side menu can be fixed to the top of the page.
 * The menu links will act as an achor to sections in the content area scroll to the section when clicked, and highlight the active section.
 * 
 * If you need to add extra items to the side menu, you can pass them along as `extraItems`:
 * 
 * const extraMenuItems = <Typography.Paragraph>This is extra content that will be rendered under the side menu</Typography.Paragraph>
 * <ScrollSidePageWrapper extraItems={extraMenuItems}>
 * </ScrollSidePageWrapper>
 */
const ScrollSidePageWrapper: FC<ScrollSidePageWrapperProps> = ({
  menuProps,
  content,
  header,
  extraItems,
  view = "anchor",
  headerHeight = 170,
}) => {
  const [isFixedTop, setIsFixedTop] = useState(false);
  const systemFlag = useSelector(getSystemFlag);
  const isCore = systemFlag === SystemFlagEnum.core;

  const systemHeaderHeight = isCore ? coreHeaderHeight : msHeaderHeight;
  const contentPaddingY = isCore ? 24 : 26;

  const handleScroll = () => {
    let isMounted = true;
    if (isMounted) {
      const scrollHeight = window.scrollY ?? window.pageYOffset;
      if (scrollHeight > 0 && !isFixedTop) {
        setIsFixedTop(true);
        // oddly, isFixedTop never ends up being true here, even though it seems to take effect,
        // so took out isFixedTop is true out from condition
      } else if (scrollHeight <= 0) {
        setIsFixedTop(false);
      }
    }
    return () => (isMounted = false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
  }, []);

  const hasMenu = Boolean(menuProps);
  const hasHeader = Boolean(header);

  const contentClass = [hasMenu && "side-menu--content", isFixedTop && "with-fixed-top"]
    .filter(Boolean)
    .join(" ");
  const topOffset = headerHeight + systemHeaderHeight;

  const menuTopOffset = hasHeader || isFixedTop ? topOffset : 0;
  const contentTopOffset = hasHeader && isFixedTop ? headerHeight : 0;

  return (
    <div className={`scroll-side-menu-wrapper scroll-side-menu-view--${view}`}>
      {hasHeader && (
        <div
          className={isFixedTop ? "view--header fixed-scroll" : "view--header"}
          style={{ paddingBottom: 0, height: headerHeight }}
        >
          {header}
        </div>
      )}
      {hasMenu && (
        <div
          className={isFixedTop ? "side-menu--fixed" : "side-menu"}
          style={{ top: menuTopOffset }}
        >
          {/* the 24 matches the margin/padding on the menu/content. Looks nicer */}
          <ScrollSideMenu offsetTop={topOffset + contentPaddingY} {...menuProps} view={view} />

          {extraItems ? extraItems : ''}
        </div>
      )}
      <div className={contentClass} style={{ top: contentTopOffset }}>
        {content}
      </div>
    </div>
  );
};

export default ScrollSidePageWrapper;
